import React from "react"
import { graphql } from "gatsby"
import getComponentsMap from "../utilities/AvailableComponentMap"
import loadComponents from "../utilities/componentLoader"
import getSitewideMessages from "../utilities/getSitewideMessages"
import BaseLayout from "./baseLayout"
import "../styles/main.scss"

export const query = graphql`
  query ($slug: String!) {
    ecsPage(slug: { eq: $slug }) {
      content
      id
      slug
    }

    ecsSitemap(slug: { eq: $slug }) {
      id
      content {
        visibleInBreadcrumbs
        label {
          key
          value
        }
        link {
          type
          value
        }
      }
      parent {
        id
      }
    }

    allEcsSitemap {
      nodes {
        id
        content {
          label {
            key
            value
          }
          link {
            queryString {
              preserveQsData
            }
            type
            value
          }
          visibleInBreadcrumbs
        }
        slug
        parent {
          id
        }
      }
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }
  }
`

const ArticleRightRailPage = ({ data, location }) => {
  const availableComponentsMap = getComponentsMap()
  const components = loadComponents(
    availableComponentsMap,
    data.ecsPage.content.components,
    data.allEcsSitemap, 
    data.ecsSitemap
  )
  const sitewideMessages = getSitewideMessages(
    data.ecsSitewideMessages.content,
    location.pathname
  )

  const headData = {
    pageInformation: components["Page_Information"],
    tagman: components["Tagman"],
    htmlAttributes: { lang: "en-us" },
    canonicalUrl: location.href
  }

  const globalHeader = {
    pageUrl: data.ecsPage.slug
  }

  return (
    <>
      <BaseLayout
        head={headData}
        disclaimers={components["Disclaimer"]}
        breadcrumbs={components["Breadcrumbs"]}
        sitewideMessages={sitewideMessages}
        globalHeader={globalHeader}>

        {components["Carousel"] && (
          <div className="featured-area">
            <div className="article-hero">{components["Carousel"]}</div>
          </div>
        )}
        {components["Dynamic_Cta"]}
        <section id="content-link" role="main" tabIndex="-1">
          <div
            className={`ls-area article ${
              components["Right_Rail"] ? "has-rightrail" : ""
            }`}
            id="content-body">
            {components["Link_List"] && (
              <div className="tds-row">
                <div class="tds-col-md-12">{components["Link_List"]}</div>
              </div>
            )}
            {components["Body_Content"]}
            {components["Page_Promo"]}
          </div>
          {components["Right_Rail"]}
          {/* todo: insert transaction (find an agent) component here */}
        </section>
        {components["Footer_Cap"]}
      </BaseLayout>
    </>
  )
}

export default ArticleRightRailPage
