import * as React from "react"
import { graphql } from "gatsby"
import TopicHubPage from "../templates/topicHubPage"
import ArticlePage from "../templates/articlePage"
import ArticleRightRailPage from "../templates/articleRightRailPage"

export const query = graphql`
  query ($slug: String!) {
    ecsPage(slug: { eq: $slug }) {
      content
      id
      slug
      template
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }
  }
`

const IndexPage = ({ data, location }) => {
  if (data) {
    const template = data.ecsPage.template

    switch (template) {
      case "Article":
        return <ArticlePage data={data} location={location} />
      case "Article Right Rail":
        return <ArticleRightRailPage data={data} location={location} />
      case "Topic Hub":
        return <TopicHubPage data={data} location={location} />
    }
  }

  return <></>
}

export default IndexPage
